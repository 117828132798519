import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// @ts-ignore

import { AutorizacaoContextProvider } from './context/AutorizacaoContext';

const container : any = document.getElementById('root');
const root = createRoot(container);
root.render(
    <AutorizacaoContextProvider>
        <App />
    </AutorizacaoContextProvider>
);

