import React, {useEffect, useState} from "react";
import { Col, Container, Item, List, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import Timeline from "../components/timeline/Timeline";
import './Municipio.style.scss'
import {useNavigate} from "react-router-dom";
export default function Prefeitos() {

    const [prefeitoList, setPrefeitoList] = useState<any>()
    const { getPrefeitos } = useRequisicaoSite();

    const navigate =  useNavigate();

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getPrefeitos().then(r => {
                setPrefeitoList(r.data)
            });

        };
        handleDataFiltro()
    }, [])
    return (
        <div className={'secretaria'}>
            <Container>
                <Row>
                    <Col className="col-3">
                        <List title="O Município" mt={'5'}>
                            <a href='#' onClick={() => navigate('/o-municipio')}>
                                <Item py={2} showDividerAfter>
                                    <Row>
                                        <Col auto>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Col>
                                        <Col>
                                            História de Vilhena
                                        </Col>
                                    </Row>
                                </Item>
                            </a>
                            <a href='#' onClick={() => navigate('/prefeitos')}>
                                <Item py={2} showDividerAfter>
                                    <Row>
                                        <Col auto>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Col>
                                        <Col>
                                            Galeria de Prefeitos
                                        </Col>
                                    </Row>
                                </Item>
                            </a>
                        </List>
                    </Col>
                    <Col>
                        <div className="mt-5">
                            <h1 className={'titulo mt-10'}>Galeria de Prefeitos </h1>
                        </div>

                        <Timeline prefeitoList={prefeitoList}></Timeline>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}