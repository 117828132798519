import React,{ useState, useEffect, useRef } from "react";
import './noticia-painel.style.scss'
import * as Entities from "html-entities";
import {useNavigate} from "react-router-dom";
import {Col, Row} from "../../../components";

const NoticiaCard = (props) => {
    const { noticiaList, fluid } = props;
    const navigate =  useNavigate();
    const handleVerNoticia = async (noticia) => {
        navigate('/acesso-a-informacao/noticia/' + noticia.slug , {
            state: { noticia: noticia }, // Pass the user ID as a prop
        });
    };

    return (<>
        {noticiaList &&
        <div className={'noticia-lista-card-componente'}>
            <Row className={'ml-0 mr-0'}>
                {noticiaList.map((linha) => {
                    return (

                        <Col className={ fluid === true ? 'col-sm-12 col-md-3 col-md-3 jasgrid' :'col-sm-12 col-md-6 col-md-6 jasgrid'} key={'noticia-lista-card-componente-' + linha.id}>
                            <div className="box-item">
                                <div className="box-post">
                            {/*<span className="label bg-warning p-1">*/}
                            {/*    <a href="#" rel="tag">Travel</a>*/}
                            {/*</span>*/}
                                    <h5>
                                        <a href="#" onClick={() => handleVerNoticia(linha)}
                                           dangerouslySetInnerHTML={{__html: linha.titulo}}/>
                                    </h5>
                                    <span className="meta">
                                    <span><i className="glyphicon glyphicon-time"></i> {linha.data}</span>
                                </span>
                                </div>
                                <img
                                    src={linha.capaUrl}
                                    alt={linha.titulo}
                                    className=""/>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
        }</>
    )
}

export default NoticiaCard;
