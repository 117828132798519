import classNames from 'classnames';
import React from 'react';
import IMtProps from '../IMtProps';
import { useSpreadProps } from '../Util/useSpreadProps';
import { useMtProps } from '../Util/useMtProps';
import Container from '../Container';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import Button from '../Button';
import CustomTag from '../CustomTag/CustomTag';
import {Footer} from "../index";
import  "./Modal.Style.scss";

export interface ModalProps  extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
    /** Título da Modal */
    title?: string
    /** Se mostra ou não o botão de fechar */
    showCloseButton?: boolean
    /** Se mostra ou não o botão de confirmar */
    showConfirmButton?: boolean
    /** Evento a ser executado ao clicar no botão de fechar */
    onCloseButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
    /** Evento a ser executado ao clicar no botão de confirmar */
    onConfirmButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined

    //CHECAR TODAS ESSAS CONDIÇÕES---------------------
    /** Se a modal possui um footer. */
    useModalFooter?:boolean
    /** Se a modal é envolvida com um scrim. */
    //CHECAR TODAS ESSAS CONDIÇÕES----------------------

    useScrim?:boolean
    /** Se a modal está aberta. */
    modalOpened?:boolean
}

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
    ({className, children, title , showCloseButton = true, showConfirmButton = true, useScrim = false, modalOpened = false, useModalFooter = true, onCloseButtonClick = () => {/** */}, onConfirmButtonClick = () => {/** */}, ...props}, ref) => {
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);

        return (
            <CustomTag
                tagName={useScrim && 'div'}
                className={classNames(
                    'br-scrim-util',
                    'foco',
                    {'active' : modalOpened}
                )}
            >
                <div
                    ref={ref}
                    className={classNames(
                        'br-modal',
                        className,
                        ...mtProps,
                        'modal-flexible'
                    )}
                    {...spreadProps}

                >
                    <Container fluid p={0}
                               // p-sm={4}
                    >
                        {(title) &&
                            <div className="br-modal-header">
                                {title &&
                                    <div className="br-modal-title" title={title}>{title}</div>
                                }
                            </div>
                        }

                        <ModalBody>
                            {children}
                        </ModalBody>

                        {useModalFooter &&
                            <ModalFooter>
                                {showCloseButton &&
                                    <div className="col-3" >
                                        <Button
                                            secondary={true}
                                            onClick={onCloseButtonClick}
                                        >
                                            <i className="fas fa-times"></i>
                                            Fechar
                                        </Button>
                                    </div>
                                }
                                {showConfirmButton &&
                                    <div className="col-3" >
                                        <Button
                                            primary={true}
                                            onClick={onConfirmButtonClick}
                                        >
                                            <i className="fas fa-check"></i>
                                            Confirmar
                                        </Button>
                                    </div>
                                }
                            </ModalFooter>
                        }
                    </Container>
                </div>
            </CustomTag>
        );
    }
);

Modal.displayName = 'Modal';

export default Object.assign(Modal, {
    Body: ModalBody,
    Footer: ModalFooter
});