import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, { useEffect, useState} from "react";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";

export default function Noticia({noticia, setNoticia}) {
    const location = useLocation();
    const navigate =  useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const { slug} = useParams();
     const [noticiaShow, setNoticiaShow] = useState<any>([])
    const [capa, setCapa] = useState<string>('')
    const { getNoticias } = useRequisicaoSite();

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getNoticias({slug : slug}).then(r => {
                const result = r.data.items[0];
                setCapa(result.capaUrl)
                console.log(result)
                setNoticiaShow(result)
            });
        };
        const noticia = location.state?.noticia;
        if (noticia) {
            setCapa(noticia.capaUrl)
            setNoticiaShow(noticia)
        } else {
            handleDataFiltro()
        }

    }, []);
    return (
        <div className={'post-view'}>
            <img src={capa} className="rounder-md" alt={noticiaShow.titulo}/>
            <h1 className="card-title"
                dangerouslySetInnerHTML={{__html: noticiaShow.titulo}}/>
            <div className="card-text"
                dangerouslySetInnerHTML={{__html: noticiaShow.artigo}}/>
        </div>
    )
}