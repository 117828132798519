import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './PerguntasFrequentes.style.scss'
import {Card, Col, Collapse, Item, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
export default function PerguntasFrequentes() {

    const { getPerguntasFrequentes } = useRequisicaoSite();


    const [perguntasFrequentes, setPerguntasFrequentes] = useState<any>([])

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getPerguntasFrequentes().then(r => {
                setPerguntasFrequentes(r.data)
            });

        };
        handleDataFiltro()
    }, [])

    return (
        <div className={'perguntas-frequentes'}>
            <h1 className={'titulo'}>Perguntas Frequentes </h1>
            {Array.isArray(perguntasFrequentes) && perguntasFrequentes?.map((linha) => (
                <Collapse title={linha.titulo}>
                    <div dangerouslySetInnerHTML={{__html: linha.artigo}}/>
                </Collapse>
            ))}
            {!Array.isArray(perguntasFrequentes) && <p>No data available.</p>}

        </div>

    )
}