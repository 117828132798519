import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Concurso.style.scss'
import {Card, Col, Collapse, Item, List, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import * as Entities from "html-entities";
import Select from "../../components/Select/Select";
export default function Concurso() {

    const { getConcursos, getConcursosAnos } = useRequisicaoSite();
    const navigate =  useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [concursos, setConcuros] = useState<any>([])
    const [paginacao, setPaginacao] = useState<any>([])
    const [pageNumber, setPageNumber] = useState<number>();
    const [pageSize, setPageSize] = useState<number>();
    const [filtro, setFiltrar] = useState<number>('')
    const [anos, setAnos] = useState<any>([])

    const handleFilterAno = (value) => {
        urlSearchParams.set('ano', String(value))
        const queryString = urlSearchParams.toString();
        navigate("?" + queryString)
        handleDataConcurso()
    }
    const handleDataConcursoAnos = async () => {
        await getConcursosAnos().then(r => {
            const result = r.data;
            setAnos(result)
        });

    };
    const handleDataConcurso = async () => {
        await getConcursos(params).then(r => {
            const result = r.data.items;
            const paginacao = r.data.pagination;
            setPaginacao(paginacao)
            setConcuros(result)
        });

    };
// Ao trocar a página, recarregar os dados
    useEffect(() => {
        if (pageNumber !== undefined) {
            urlSearchParams.set('page', String(pageNumber))
            const queryString = urlSearchParams.toString();
            navigate("?" + queryString)
            handleDataConcurso()
        }
    }, [pageNumber]);
    // Ao trocar o tamanho da página
    useEffect(() => {
        if (pageSize !== undefined) {
            urlSearchParams.set('limite', String(pageSize))
            const queryString = urlSearchParams.toString();
            navigate("?" + queryString)
            handleDataConcurso()
        }
    }, [pageSize]);

    useEffect(() => {
        handleDataConcursoAnos()
        handleDataConcurso()
    }, [pageSize, pageNumber])

    return (
        <div className={'concursos'}>
            <h1 className={'titulo'}>Concursos </h1>

            <Row>
                <Col sm={12} md={12} lg={12} mt="3" className={'identificacao'}>
                    <Select
                        id="identificacao"
                        label="Filtrar por data publicação"
                        value={filtro}
                        placeholder="Selecione"
                        onChange={handleFilterAno}
                        options={anos}
                    />
                </Col>
            </Row>
            {Array.isArray(concursos) && concursos?.map((linha) => (
                <Collapse title={linha.titulo}>
                    <Row>
                        <Col m={3}><span className="legend">Data Publicação: {linha.data_publicacao}</span></Col>
                        <Col m={3}><span className="legend">Data Prova: {linha.data_concurso}</span></Col>
                    </Row>
                    <div className={'m-3'} dangerouslySetInnerHTML={{__html: linha.artigo}}/>
                    <Card m={3}>
                        <List title="Anexos" my={'5'}>
                            {Array.isArray(linha.anexos) && linha.anexos?.map((anexo) => (
                                <a href={anexo.arquivo} target='_blank'>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="fas fa-cloud-download-alt"></i>
                                            </Col>
                                            <Col>
                                                {anexo.descricao}
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                            ))}
                        </List>
                    </Card>
                    {linha.links.length > 0 && Array.isArray(linha.links) &&
                        <Card m={3} >
                            <List title="Links Úteis" mt={'5'}>
                                {linha.links?.map((link) => (
                                    <a href={link.link} target='_blank'>
                                        <Item showDividerAfter>
                                            <Row>
                                                <Col auto>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </Col>
                                                <Col>
                                                    {link.descricao}
                                                </Col>
                                            </Row>
                                        </Item>
                                    </a>
                                ))}
                            </List>
                        </Card>
                    }
                </Collapse>
            ))}
            {!Array.isArray(concursos) && <p>No data available.</p>}
            <Pagination
                type="contextual"
                itemCount={paginacao.total}
                onChange={(value) => setPageNumber(value)}
                onChangePageSize={(value) => setPageSize(value)}
            />
        </div>

    )
}