import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Buscar.style.scss'
import {Card, Col, Collapse, Container, Item, List, Pagination, Row, Tab} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import * as Entities from "html-entities";
export default function Buscar() {

    const { getNoticias, getSecretarias, getLinks, getPerguntasFrequentes,getDownloads } = useRequisicaoSite();
    const {sobre} = useParams();
    const navigate = useNavigate();


    const [total, setTotal] = useState<number>(0)
    const [downloads, setDownloads] = useState<any>([])
    const [noticias, setNoticias] = useState<any>([])
    const [noticiasT, setNoticiasT] = useState<any>([])
    const [secretarias, setSecretarias] = useState<any>([])
    const [links, setLinks] = useState<any>([])
    const [perguntasFrequentes, setPerguntasFrequentes] = useState<any>([])
    const handleDataNoticias = async () => {
        await getNoticias({buscar: sobre}).then(r => {
            // setNoticias(r.data.items)
            // setNoticiasT(r.data.pagination)
            // setTotal(total+ r.data.pagination.total)
        });

    };
    const handleDataSecretarias = async () => {
        await getSecretarias({buscar: sobre}).then(r => {
            setSecretarias(r.data)
            setTotal(total+ r.data.length)
        });

    };
    const handleDataLinks = async () => {
        await getLinks({buscar: sobre}).then(r => {
            setLinks(r.data)
            setTotal(total+ r.data.length)
        });

    };
    const handleDataPerguntasFrequentes = async () => {
        await getPerguntasFrequentes({buscar: sobre}).then(r => {
            setPerguntasFrequentes(r.data)
            setTotal(total+ r.data.length)
        });

    };
    const handleDataDownload = async () => {
        await getDownloads({buscar: sobre}).then(r => {
            setDownloads(r.data)
            setTotal(total+ r.data.length)
        });

    };
    const totalBusca = () => {

    }
    const handleVerNoticia = async (noticia) => {
        navigate('/acesso-a-informacao/noticia/' + noticia.id + '/' +  Entities.decode(noticia.titulo), {
            state: { noticia: noticia }, // Pass the user ID as a prop
        });
    };
    useEffect(() => {
        setNoticiasT(0)
        handleDataNoticias()
        handleDataPerguntasFrequentes()
        handleDataLinks()
        handleDataDownload()
    }, [sobre])

    return (
        <Container>

            <div className={'busca mt-5'}>
                <h1 className={'titulo'}>Busca </h1>
                <div className={'h3'}>"{sobre}"</div>
                {total &&
                    <p className={'ml-1'}>
                        <span className="legend">{total}</span> Resultados encontrados
                    </p>
                }
                <Tab>
                    {noticias.length > 0  && Array.isArray(noticias)  &&
                        <Tab.Content title={"Noticias ("+noticiasT.total+")"} icon="fas fa-image">
                            <Row>
                            {noticias?.map((linha) => (
                                <Col sm={12} md={6} lg={6}>
                                    <a href={'#'} onClick={() => handleVerNoticia(linha)}>
                                        <Card mt='5' p={3} className="rounder-md">
                                            <div>
                                                <h3 className="card-title mr-3 my-0"
                                                    dangerouslySetInnerHTML={{__html: linha.titulo}}/>
                                                <p className="card-text mr-3 mt-1"
                                                   dangerouslySetInnerHTML={{__html: linha.linha_fina}}/>
                                                <div className="text-right">
                                                    <p className="card-text mr-3 p-0 mt-0">
                                                        <small className="text-muted">
                                                            {linha.data}
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Card>
                                    </a>
                                </Col>
                                ))}
                            </Row>
                        </Tab.Content>
                    }
                    {Array.isArray(links) && links.length > 0 &&
                    <Tab.Content title={"Links Úteis ("+links.length+")"} icon="fas fa-image">
                        <List>
                            {links?.map((item) => {
                                return (
                                    <Item link={item.link} key={'download-collapse-' + item.id} showDividerAfter
                                          className={'py-3'}>
                                        <Row>
                                            <Col auto>
                                                <i className="fas fa-external-link-alt"></i>
                                            </Col>
                                            <Col>
                                                <span dangerouslySetInnerHTML={{__html: item.titulo}} />
                                            </Col>
                                        </Row>
                                    </Item>
                                )
                            })}
                            {!Array.isArray(links) && <p>No data available.</p>}
                        </List>
                    </Tab.Content>
                    }
                    {perguntasFrequentes.length > 0  && Array.isArray(perguntasFrequentes) &&
                        <Tab.Content title={"Perguntas Frequentes ("+perguntasFrequentes.length+")"} icon="fas fa-image">
                            {perguntasFrequentes?.map((linha) => (
                                <Collapse title={linha.titulo}>
                                    <div dangerouslySetInnerHTML={{__html: linha.artigo}}/>
                                </Collapse>
                            ))}
                            {!Array.isArray(perguntasFrequentes) && <p>No data available.</p>}
                        </Tab.Content>
                    }
                    {downloads.length > 0 && Array.isArray(downloads) &&
                    <Tab.Content title={"Downloads ("+downloads.length+")"} icon="fas fa-image">
                        {downloads?.map((linha) => (
                            <Collapse title={linha.categoria} key={'download-collapse-' + linha.id}>
                                <List>
                                    {Array.isArray(linha.downloads) && linha.downloads?.map((item) => {
                                        return (
                                            <Item link={item.arquivo} key={'download-collapse-' + item.id} showDividerAfter>
                                                <Row>
                                                    <Col auto>
                                                        <i className="fas fa-cloud-download-alt"></i>
                                                    </Col>
                                                    <Col>
                                                        <span dangerouslySetInnerHTML={{__html: item.descricao}}/>
                                                    </Col>
                                                </Row>
                                            </Item>
                                        )
                                    })}
                                </List>
                            </Collapse>
                        ))}
                        {!Array.isArray(downloads) && <p>No data available.</p>}
                    </Tab.Content>
                    }
                </Tab>

            </div>
        </Container>

    )
}