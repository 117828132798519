import React, { useState, createContext } from "react";
import Swal from "sweetalert2";
import api from "../services/api";
import { getCookie, setCookie } from "../utils";

const AutorizacaoContext = React.createContext(null);

const AutorizacaoContextProvider: React.FC<React.ReactNode> = ({ children }) => {

    const [authGovBr, setAuthGovBr] = useState<any[]>(getCookie('authGovBr') || false);
    const [authSisava, setAuthSisava] = useState(false);
    const [authValidacoes, setAuthValidacoes] = useState<any[]>(getCookie('validacoes') || false);
    const [access_token, setAccessToken] = useState<any[]>(getCookie('access_token') || undefined);

    async function getAuthSisava(access_token) {
        await api.get('/auth/usuario', {
            headers: {
                'Authorization': 'Bearer ' + access_token
            }
        })
            .then((userData) => {
                setAuthSisava(userData.data.data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Sua sessão expirou!',
                    text: 'É necessário realizar login novamente!',
                    // footer: '<a href="">Why do I have this issue?</a>'
                }).then((result) => {
                    if (result.isConfirmed) {
                        logout();
                    }
                });
                console.log(error)
            });
    }
    const logout = async () => {
        await api.get('/auth/logout')
            .then((res) => {
                console.log(res.data);
                setAuthGovBr([]);
                setAuthSisava(false);
                setCookie('authGovBr', '', 0);
                setCookie('access_token', '', 0);
                setCookie('validacoes', '', 0);
                window.location.href = res.data.data.uri;
            });
    };
    return (
        <AutorizacaoContext.Provider value={{authSisava, setAuthSisava, authValidacoes, setAuthValidacoes,
            authGovBr, setAuthGovBr, access_token, setAccessToken, getAuthSisava, logout}}>
            {children}
        </AutorizacaoContext.Provider>
    );

}
export {AutorizacaoContextProvider, AutorizacaoContext};