import React from 'react';
const moment = require('moment-timezone');

function setCookie(cname, cvalue, exdays = 1) {
    let expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    if (exdays > 0) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkCookie() {
    let authGovBr = getCookie("authGovBr");
    if (authGovBr === "" || authGovBr === false) {
        // alert("Sessão expirada");
        window.location.href = process.env.REACT_APP_
    }
}

function formatDate(date, format = 'DD/MM/YYYY') {
    return moment(date).tz('America/Sao_Paulo').format(format);
}

function removerMascara(nu_cpf){
    // Retira tudo que nao é número
    return nu_cpf.replace(/[^0-9]+/g, '');
}

function isValidBase64(str) {
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

export { formatDate, setCookie, getCookie, checkCookie, removerMascara, isValidBase64}