import * as Yup from 'yup';

export const useOuvidoriaFormSchema = () => {
    const ouvidoriaFormInicial = {
        identificacao: '',
        tipo: '',
        nome: '',
        email: '',
        cpf: '',
        rg: '',
        telefone: '',
        cep: '',
        cidade: '',
        bairro: '',
        endereco: '',
        solicitacao: '',
        arquivo: null
    }

    const ouvidoriaFormSchema = Yup.object().shape({
        identificacao: Yup.string().required("Campo Obrigatório"),
        tipo: Yup.string().required("Campo Obrigatório"),
        solicitacao: Yup.string().required("Campo Obrigatório"),
        nome: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        email: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        cpf: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        rg: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        telefone: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        cep: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        cidade: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        bairro: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
        endereco: Yup.string().when('identificacao', {
            is: (val) => val === 'aberto' || val === 'sigiloso',
            then: Yup.string().required("Campo Obrigatório"),
            otherwise: Yup.string(),
        }),
    })

    return {
        ouvidoriaFormInicial,
        ouvidoriaFormSchema,
    }
}