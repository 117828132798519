import React, { useCallback, useEffect, useState } from "react";
import "./timeline.style.scss";
import {Col, Item, Row} from "../../../components";

const Timeline = (props) => {
    const { prefeitoList } = props;
    return (
        <>

            <div className="main-timeline">
                {Array.isArray(prefeitoList) && prefeitoList?.map((linha) => (
                    <div className="timeline">
                        <div className="timeline-content">
                            <div className="circle">
                            <span>
                            <img
                                src={linha.foto}/>
                            </span>
                            </div>
                            <div className="content">
                                <span className="year">{linha.inicio_mandato} - {linha.fim_mandato}</span>
                                <h1 className="title"
                                    dangerouslySetInnerHTML={{__html: linha.prefeito}}/>
                                <p className="description">
                                </p>
                                <div className="icon"><span></span></div>
                            </div>
                        </div>
                    </div>
                ))}
                {!Array.isArray(prefeitoList) && <p>No data available.</p>}

            </div>
        </>
    )
}

export default Timeline;
