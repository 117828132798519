import * as React from 'react';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from "react-router-dom";
import IMtProps from '../IMtProps';
import { useSpreadProps } from '../Util/useSpreadProps';
import { useMtProps } from '../Util/useMtProps';
import List from '../List';
import Button from '../Button';
import Input from '../Input';
import Select from "../Select";
import Col from "../Col";
import Row from "../Row";
import InputCheckbox from '../Checkbox'
import Divider from '../Divider';
import CollapseTable from './CollapseTable';
import { updateQueryStringParameter } from '../Util/Util';
import { SelectOptions } from '../Select/Select';
import { InputRef } from '../Input/Input';
import { ButtonRef } from '../Button/Button';
import useCommonProperties from '../Util/useCommonProperties';
import useUniqueId from '../Util/useUniqueId';
import Pagination from '../Pagination';
import "./Table.style.scss"
import TabContent from "../Tab/TabContent";
export interface IHeader {
    field: string,
    label: string
}
export interface IFooterButton {
    type: string,
    value: string,
    hideIfNotChecked?: boolean,
    handleOnClick(): any
}
export interface ISubHeader {
    field: string,
    label: string
}
export interface IItemPage {
    label: string,
    value: string
}
export interface IData {
    pageNumber?: number,
    recordCount?: number,
    pageSize?: number,
    data: any[]
}
export interface ISearchEvent extends React.MouseEvent<HTMLButtonElement, MouseEvent> {
    searchText: string;
}
export interface IActions {
    label: string,
    icon: string,
    disabled?: boolean,
    handleIcon(): void | any
    handleOnClick(): void | any
}
export interface IEditColuna {
    field: string,
    type: string,
    mask?: string,
    clickOn?: boolean,
    handleOnSubmit(): any
}

export interface IColChildren {
    field: string,
    colChildren(): void | any
}

export interface TableProps extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
    id?: string,
    /** Título da tabela */
    title?: string,
    /** Mostra Checkbox de Select All e*/
    checked?: boolean,
    /** Se mostra a topbar da tabela */
    showTopBar?: boolean,
    /** Indica o nome da coluna que tem o dado ID*/
    idData?: string,

    /** Indica se o icone de edição ficará desabilitado - Deve ser expecificado no retorno de cada array igual é feito no idData*/
    disableEdit?: string,

    /** Indica se o icone de exclusão ficará desabilitado - Deve ser expecificado no retorno de cada array igual é feito no idData*/
    disableDelete?: string,

    /** Densidade da tabela.
     *
     * - small: pequena
     * - medium: média
     * - large: alta
     */
    density?: 'small' | 'medium' | 'large',

    /** Se enviar o metodo onClickRow */
    onClickRow?: any,
    /** Se mostra ou não o menu de densidade. */
    showDensityButtons?: boolean,

    /** Se mostra ou não o menu de busca. */
    showSearch?: boolean,

    /** Se mostra ou não a barra de selecionados. */
    showSelectedBar?: boolean,

    /** Se mostra ou não a paginação */
    showPagination?: boolean,

    /** Mostra ou não o seletor de página. */
    showPageSelector?: boolean,

    /** Headers da tabela. */
    headers?: string[] | IHeader[],

    /** Botoes footer da tabela. */
    footerButtons?: string[] | IFooterButton[],

    /** SubHeaders da tabela. */
    subHeaders?: string[] | ISubHeader[],

    /** Ação da linha */
    actionsTable?: IActions[],

    /** Ativa a edição da coluna*/
    editColuna?: IEditColuna[],

    customColChildren?: IColChildren[],

    /** Dados da tabela. */
    data?: IData | object[],

    /** Endpoint com os dados da tabela. */
    endpoint?: string,

    /** Url da pagina. */
    paramsUrl?: object[],

    /** Url da pagina. */
    setParamsUrl?: any,

    /** Ao clicar no botão de ir para a próxima página. */
    onClickNextPage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,

    /** Ao clicar no botão de ir para a página anterior. */
    onClickPrevPage?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,

    /** Ao realizar busca. */
    onSearch?: (event: ISearchEvent) => void,

    /** Array para preencher a combo de itens por página. */
    arrayItemsPerPage?: number[],

    /** Sobrescreve o marcador da página atual na área de paginação. */
    currentPageNumber?: number,

    /** Sobrescreve o marcador de quantidade de páginas na área de paginação. */
    currentPerPageNumber?: number,

    /** Sobrescreve o marcador de total de registros na área de paginação. */
    currentTotalRegistros?: number,

    customRecordsProp?: string,

    customPageNumberProp?: string,

    customPageSizeProp?: string

    /** Herdar os checks da tabela. */
    herdarCheck?: boolean,
}

const Table = React.forwardRef<HTMLDivElement, TableProps>(
    ({
        className,
        children,
        id,
        title,
        checked = false,
        onClickRow,
        idData,
        actionsTable,
        editColuna,
        showDensityButtons = true,
        showSearch = true,
        onSearch,
        showTopBar = true,
        showSelectedBar = true,
        showPagination = true,
        density,
        headers,
        footerButtons,
        subHeaders,
        data,
        customColChildren,
        endpoint,
        paramsUrl,
        setParamsUrl,
        onClickNextPage,
        onClickPrevPage,
        showPageSelector = false,
        arrayItemsPerPage,
        currentPageNumber,
        currentPerPageNumber,
        currentTotalRegistros,
        customRecordsProp = 'data',
        customPageNumberProp = 'pageNumber',
        customPageSizeProp = 'pageSize',
        herdarCheck = false,
        disableEdit,
        disableDelete,
        ...props
    }, ref) => {
        const fid = useUniqueId(id, 'table_____');
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);
        const search = useLocation().search;
        const urlSearchParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const [searchParams, setSearchParams] = useSearchParams(params);

        const [tableData, setTableData] = useState<any[]>([]);
        const [defaultSearch, setDefaultSearch] = useState<string>('');
        const [atualizando, setAtualizando] = useState<boolean>(false);
        const [currentEndpoint, setCurrentEndpoint] = useState<string>('');
        const [pageNumber, setPageNumber] = useState<number>();
        const [pageSize, setPageSize] = useState<number>();
        const [recordCount, setRecordCount] = useState<number>();
        const [pageOptions, setPageOptions] = useState<SelectOptions[]>();
        const pageCount = useRef<number | null>(null);

        const [searchExpanded, setSearchExpanded] = useState<boolean>(false);
        const [alreadyExpanded, setAlreadyExpanded] = useState<boolean>(false);

        const [currentDensity, setCurrentDensity] = useState<string>('medium');

        const refDiv = useRef(null);
        const refInput = useRef<InputRef>(null);
        const refSearchExpander = useRef<ButtonRef>(null);

        const [collapsed, setCollapsed] = useState<number>(null);
        const [collapseRef, setCollapsedRef] = useState<any>([]);

        const [isCheckAll, setIsCheckAll] = useState(false);
        const [isCheck, setIsCheck] = useState([]);

        const [inputIndex, setInputIndex] = useState<number>('');
        const [inputType, setInputType] = useState<string>('');
        const [inputValue, setInputValue] = useState<string>('');
        const [inputField, setInputField] = useState<string>('');
        const [inputMask, setInputMask] = useState<string>('');
        const [inputFunction, setInputFunction] = useState<any>('');
        const [inputButton, setInputButton] = useState<any>(false);

        const refInputEditable = useRef<InputRef>(null);

        const openCollapsedRef = (ref, index) => (e) => {
            setCollapsedRef[index] = e;
        };
        const handleToggleCollapse = index => (e) => {
            if (collapsed === index) {
                setCollapsed(null)
            } else {
                setCollapsed(index)
            }
            if (e.target.tagName === 'I') {
                if (collapsed === index) {
                    e.target.className = 'fas fa-chevron-down'
                } else {
                    e.target.className = "fas fa-chevron-up"
                }
            }
            if (e.target.tagName === 'BUTTON') {
                if (collapsed === index) {
                    e.target.innerHTML = "<i class='fas fa-chevron-down'></i>"
                } else {
                    e.target.innerHTML = "<i class='fas fa-chevron-up'></i>"
                }
            }
        };

        /**
         *
         * @param e
         */
        const handleSelectAll = e => {
            setIsCheckAll(!isCheckAll);
            setIsCheck(tableData.map(li => li[idData]));
            if (isCheckAll) {
                setIsCheck([]);
            }
        };

        useEffect(() => {
            setIsCheck([]);
            if (herdarCheck && data.length > 0) {
                setIsCheck(data.map(li => li[idData]));
            }
        }, [herdarCheck, data]);

        const handleClick = useCallback(e => {
            const { id, checked } = e.target;
            setIsCheck([...isCheck, Number(id)]);
            if (!checked) {
                setIsCheck(isCheck.filter(item => item !== Number(id)));
            }
        }, [isCheck]);


        /**
         * @param row Informa a linha que vai encontrar o compo a ser editado
         * @param type O tipo de input a ser inserido
         * @param value O valor inicial do Input
         * @param field o Nome do input
         * @param mask Caso tiver insere o input mask
         * @param handleFunction caso tenho um btn para salvar o input
         */
        const handleEditColun = (row: number, type: string, value: string, field: string, mask?: string, handleFunction?: any, clickOn?: boolean) => (e: React.TdHTMLAttributes<HTMLTableColElement>) => {
            setInputIndex(row);
            setInputField(field);
            setInputValue(value);
            setInputType(type);
            clickOn ? setInputButton(clickOn) : setInputButton(false);

            handleFunction ? setInputFunction(handleFunction) : setInputFunction('');
            mask ? setInputMask(mask) : setInputMask('');

        }

        const cancelarEdicao = (event: React.FormEvent<HTMLInputElement>) => {
            if (inputValue === event.target.value) {
                setInputField('');
                setInputValue('');
                setInputType('');
                setInputFunction(() => { });
                setInputMask('');
            }
        }

        useCommonProperties<InputRef>(ref, refDiv);

        const handleClickNextPage = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            onClickNextPage?.(event);

            if (!atualizando && endpoint) {
                setPageNumber((currentOffset) => {
                    if (typeof currentOffset !== 'undefined') {
                        return currentOffset + 1;
                    } else {
                        return currentOffset;
                    }
                });
            }

        }, [atualizando, endpoint, onClickNextPage]);

        const handleClickPreviousPage = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            onClickPrevPage?.(event);

            if (!atualizando && endpoint) {
                setPageNumber((currentOffset) => {
                    if (typeof currentOffset !== 'undefined') {
                        return currentOffset - 1;
                    } else {
                        return currentOffset;
                    }
                });
            }

        }, [atualizando, endpoint, onClickPrevPage]);

        const handleTrocaBuscaPadrao = () => {
            onSearch?.({ searchText: defaultSearch } as ISearchEvent);

            defaultSearch !== undefined && setCurrentEndpoint((currentEndpoint) =>
                updateQueryStringParameter(currentEndpoint, 'defaultSearch', String(defaultSearch)));
        };

        const handleExpandSearch = useCallback(() => {
            setSearchExpanded(true);
            setAlreadyExpanded(true);
        }, []);

        const handleCloseSearch = useCallback(() => {
            setSearchExpanded(false);
            setDefaultSearch('');
        }, []);

        const handleKeyDownSearch = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                handleTrocaBuscaPadrao();
            }
            if (event.key === 'Escape') {
                setDefaultSearch('');
                setSearchExpanded(false);
            }
        }, []);

        const handleClickDensity = useCallback((density: string) => {
            setCurrentDensity(density);
        }, []);

        useEffect(() => {
            if (searchExpanded) {
                refInput.current?.focus();
            } else if (alreadyExpanded) {
                refSearchExpander.current?.focus();
            }
        }, [searchExpanded, alreadyExpanded]);

        /**
         * Efeito que informa aos state usando os props como verificação para o insert correto tableData
         * Em caso de não houver paginação deixa de informa o pageNumber, pageSize e recordCount
         */
        useEffect(() => {
            // Se os dados tiverem sido informados manualmente, informa-os
            if (data && (data as IData).data) {
                setTableData((data as IData).data);
                if (showPagination) {
                    setPageNumber((data as IData).pageNumber);
                    setPageSize((data as IData).pageSize);
                    setRecordCount((data as IData).recordCount);
                }
            } else if (data) {
                const dataLength = (data as any[]).length;

                setTableData(data as any[]);
                if (showPagination) {
                    setRecordCount(dataLength);
                    setPageSize(dataLength);
                    setPageNumber(0);
                }
            }


        }, [data]);


        // Ao trocar a página, recarregar os dados
        useEffect(() => {
            if (pageNumber !== undefined) {
                urlSearchParams.set('page', String(pageNumber))
                setSearchParams(urlSearchParams.toString())
            }
        }, [pageNumber]);

        // Ao trocar o tamanho da página
        useEffect(() => {
            if (pageSize !== undefined) {
                urlSearchParams.set('limite', String(pageSize))
                setSearchParams(urlSearchParams.toString())
            }
        }, [pageSize]);

        // Ao mudar a quantidade de registros ou o tamanho da página
        useEffect(() => {
            if (showPagination && recordCount !== undefined && pageSize !== undefined) {
                const currentPageCount = Math.ceil(recordCount / pageSize);
                if (currentPageCount !== pageCount.current) {
                    pageCount.current = currentPageCount;

                    setPageOptions(pageOptions => {
                        if (pageOptions?.length !== pageSize) {
                            const currentPageOptions = [];


                            for (let i = 0; i < currentPageCount; i++) {
                                currentPageOptions.push(
                                    {
                                        label: String(i + 1),
                                        value: String(i + 1)
                                    }
                                );
                            }
                            return currentPageOptions;
                        } else {
                            return pageOptions;
                        }

                    });
                }
            }

        }, [recordCount, pageSize]);

        const getItemsPerPage = useCallback(() => {
            const newItemsPerPage: IItemPage[] = [];
            const array = arrayItemsPerPage || [10, 20, 30, 50, 100];

            for (const index in array) {
                newItemsPerPage.push({
                    label: String(array[index]),
                    value: String(array[index])
                });
            }

            return newItemsPerPage;
        }, [arrayItemsPerPage]);

        return (
            <div
                ref={refDiv}
                id={fid}
                className={classNames(
                    'br-table',
                    density,
                    className,
                    currentDensity,
                    ...mtProps
                )}
                {...spreadProps}
                data-search="data-search"
                data-selection="data-selection"
                data-collapse="data-collapse"
                data-random="data-random"

            >
                <div className={classNames('table-header', { 'show': searchExpanded })}>
                    {showTopBar && <div className="top-bar">
                        <div className="table-title">{title}</div>
                        {showDensityButtons && <div className="actions-trigger text-nowrap">
                            <Button
                                circle
                                title="Ver mais opções"
                                data-toggle="dropdown"
                                data-target={`ver-mais-opcoes____${fid}`}
                                aria-label="Ver mais opções"
                                icon="fas fa-ellipsis-v"
                                dropdownItems={<>
                                    <Button onClick={() => handleClickDensity('large')} isItem data-density="small">Densidade alta
                                    </Button><span className="br-divider"></span>
                                    <Button onClick={() => handleClickDensity('medium')} isItem data-density="medium">Densidade média
                                    </Button><span className="br-divider"></span>
                                    <Button onClick={() => handleClickDensity('small')} isItem data-density="large">Densidade baixa
                                    </Button>
                                </>}
                            />
                        </div>}
                        <div className="search-trigger">
                            {showSearch && <Button ref={refSearchExpander} onClick={handleExpandSearch} circle data-toggle="search" aria-label="Abrir busca"><i className="fas fa-search" aria-hidden="true"></i>
                            </Button>}
                        </div>
                    </div>}
                    {showSearch && <div className={classNames('search-bar', { 'show': searchExpanded })}>
                        <div className="br-input">
                            <Input
                                id={`table-searchbox-${fid}`}
                                ref={refInput}
                                label="Concurso"
                                placeholder="Concurso na tabela"
                                value={defaultSearch}
                                onKeyDown={handleKeyDownSearch}
                                onChange={(event) => setDefaultSearch(event.currentTarget.value)}
                                button={<Button circle aria-label="Concurso" icon="fas fa-search" onClick={handleTrocaBuscaPadrao} />} />

                        </div>
                        <Button onClick={handleCloseSearch} circle data-dismiss="search" aria-label="Fechar busca" icon="fas fa-times" />
                    </div>}
                    <div className="selected-bar">
                        <div className="info"><span className="count">0</span><span className="text">item selecionado</span></div>
                        <div className="actions-trigger text-nowrap">
                            <Button circle inverted type="button" data-toggle="dropdown" data-target={`target02-${fid}`} aria-label="Ver mais opções" icon="fas fa-ellipsis-v" />
                            <List id={`target02-${fid}`} hidden>
                                <Button data-toggle="">Ação 1</Button>
                                <Divider />
                                <Button>Ação 2</Button>
                            </List>
                        </div>
                    </div>
                </div>
                <table>
                    {showTopBar && <caption>{title}</caption>}
                    {headers &&
                        <thead>
                            <tr>
                                {checked &&
                                    <th className={'column-checkbox'}>
                                        <InputCheckbox
                                            id={'checked-all'}
                                            name={'checked-all'}
                                            onChange={handleSelectAll}
                                            checked={isCheckAll}
                                        />
                                    </th>
                                }
                                {subHeaders &&
                                    <th className={'column-collapse'}></th>
                                }
                                {headers.map((header, index) => (
                                    <th key={index}>{(header as IHeader).label || (header as string)}</th>
                                ))}
                                {actionsTable &&
                                    <th className={'column-actions text-center'}>Ações</th>
                                }
                            </tr>
                        </thead>
                    }
                    {headers && tableData && !(headers as IHeader[])[0].label &&
                        <tbody>
                            {tableData.map((linha, index) => (

                                <tr data-linha={JSON.stringify(linha)} key={`linha-${linha[idData]}_${fid}`} onClick={onClickRow(linha)}>

                                    {checked &&
                                        <td>
                                            <InputCheckbox
                                                key={`checkbox-${linha[idData]}_${fid}`}
                                                id={linha[idData]}
                                                name={'checked-edit'}
                                                onChange={handleClick}
                                                checked={(isCheck as string[]).length > 0 && (isCheck as string[]).indexOf(linha[idData]) !== -1}
                                            />
                                        </td>
                                    }
                                    {subHeaders &&
                                        <td>
                                            <button className="br-button circle " onClick={handleToggleCollapse(index)}>
                                                <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </td>
                                    }
                                    {/*adicionar o componente filho das colunas*/}
                                    {Object.keys(linha).map((valor: string) => (
                                        <td key={`coluna-${valor}-${linha[idData]}_${fid}`}>
                                            {linha[valor]}
                                        </td>
                                    ))}

                                    {actionsTable &&
                                        <td>
                                            {(actionsTable as IActions[]).map((action: IActions, index: number) => (
                                                <>
                                                    {action.handleIcon &&
                                                        <Button key={index}
                                                            circle
                                                            block
                                                            onClick={action.handleOnClick(linha[idData])}
                                                            icon={"fas " + action.handleIcon(linha[idData])} />
                                                    }
                                                    {action.icon &&
                                                        <Button key={index}
                                                            circle
                                                            block
                                                            onClick={action.handleOnClick(linha[idData])}
                                                            icon={"fas " + action.icon} />
                                                    }
                                                </>
                                            ))}
                                        </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    }
                    {headers && tableData && (headers as IHeader[])[0].label &&
                        <tbody>
                            {Array.isArray(tableData) && tableData.map((linha, index) => (
                                <>
                                    <tr data-linha={JSON.stringify(linha)} key={`linha-${linha[idData]}_${fid}`} onClick={onClickRow ? onClickRow(linha) : null}>
                                        {checked &&
                                            <td>
                                                <InputCheckbox
                                                    key={`checkbox-${linha[idData]}_${fid}`}
                                                    id={linha[idData]}
                                                    name={'checked-edit'}
                                                    onChange={handleClick}
                                                    checked={(isCheck as string[]).length > 0 && (isCheck as string[]).indexOf(linha[idData]) !== -1}
                                                />
                                            </td>
                                        }
                                        {subHeaders &&
                                            <td>
                                                <button className="br-button circle " onClick={handleToggleCollapse(index)}>
                                                    <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </td>
                                        }
                                        {(headers as IHeader[]).map((header: IHeader, index: number) => (
                                            <>
                                                {(() => {
                                                    // Inicia a logica de coluna editavel
                                                    if (editColuna) {
                                                        let coluna = (editColuna as IEditColuna[]).find(e => e.field === header.field);
                                                        if (editColuna && coluna) {
                                                            return (
                                                                <td key={`coluna-${index}-${linha[idData]}_${fid}`}
                                                                    onClick={handleEditColun(linha[idData], coluna.type, linha[header.field], header.field, coluna.mask, coluna.handleOnSubmit, coluna.clickOn)}>
                                                                    {(() => {
                                                                        if (inputIndex === linha[idData] && inputMask && inputType === 'text' && inputField === header.field) {
                                                                            return (
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Input
                                                                                            inline
                                                                                            id={inputField}
                                                                                            mask={inputMask}
                                                                                            defaultValue={inputValue}
                                                                                            onBlur={cancelarEdicao}
                                                                                            autoFocus
                                                                                        />
                                                                                    </Col>
                                                                                    {inputButton &&
                                                                                        <Col size={2} >
                                                                                            <Button className={'mt-2'}
                                                                                                primary
                                                                                                circle
                                                                                                block
                                                                                                small
                                                                                                onClick={() => inputFunction(linha[idData])}
                                                                                                icon={"fas fa-check"} />
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        } else if (inputIndex === linha[idData] && inputType === 'text' && inputField === header.field) {
                                                                            return (
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Input
                                                                                            className={"focus-visible"}
                                                                                            inline
                                                                                            id={inputField}
                                                                                            defaultValue={inputValue}
                                                                                            onBlur={cancelarEdicao}
                                                                                            data-focus-visible-added
                                                                                        />
                                                                                    </Col>
                                                                                    {inputButton &&
                                                                                        <Col size={2} >
                                                                                            <Button className={'mt-2'}
                                                                                                primary
                                                                                                circle
                                                                                                block
                                                                                                small
                                                                                                onClick={() => inputFunction(linha[idData])}
                                                                                                icon={"fas fa-check"} />
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        } else {
                                                                            // Inclui a coluna customn
                                                                            if (customColChildren) {
                                                                                let customColumn = (customColChildren as IColChildren[]).find(e => e.field === header.field);
                                                                                if (customColumn?.field === header.field) {
                                                                                    return (
                                                                                        <>{customColumn?.colChildren(linha[header.field])}</>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <>{linha[header.field]}</>
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                return (
                                                                                    <>{linha[header.field]}</>
                                                                                )
                                                                            }
                                                                            // FIM do inclui a coluna customn
                                                                        }
                                                                        // Fim da coluna editavel
                                                                    })()}
                                                                </td>
                                                            )
                                                        } else {
                                                            return (
                                                                <td key={`coluna-${index}-${linha[idData]}_${fid}`}>
                                                                    {(() => {
                                                                        // Inclui a coluna customn
                                                                        if (customColChildren) {
                                                                            let customColumn = (customColChildren as IColChildren[]).find(e => e.field === header.field);
                                                                            if (customColumn?.field === header.field) {
                                                                                return (
                                                                                    <>{customColumn?.colChildren(linha[header.field])}</>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <>{linha[header.field]}</>
                                                                                )
                                                                            }
                                                                        } else {
                                                                            return (
                                                                                <>{linha[header.field]}</>
                                                                            )
                                                                        }
                                                                        // FIM do inclui a coluna customn
                                                                    })()}
                                                                </td>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <td key={`coluna-${index}-${linha[idData]}_${fid}`}>
                                                                {(() => {
                                                                    // Inclui a coluna customn
                                                                    if (customColChildren) {
                                                                        let customColumn = (customColChildren as IColChildren[]).find(e => e.field === header.field);
                                                                        if (customColumn?.field === header.field) {
                                                                            return (
                                                                                <>{customColumn?.colChildren(linha[header.field])}</>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <>{linha[header.field]}</>
                                                                            )
                                                                        }
                                                                    } else {
                                                                        return (
                                                                            <>{linha[header.field]}</>
                                                                        )
                                                                    }
                                                                    // FIM do inclui a coluna customn
                                                                })()}
                                                            </td>
                                                        )
                                                    }
                                                })()}
                                            </>
                                        ))}

                                        {actionsTable &&
                                            <td>
                                                {(actionsTable as IActions[]).map((action: IActions, index: number) => (
                                                    <>
                                                        {action.handleIcon &&
                                                            <Button key={index}
                                                                circle
                                                                block
                                                                onClick={action.handleOnClick(linha[idData])}
                                                                icon={"fas " + action.handleIcon(linha)}
                                                                disabled={(action.icon === "fa-trash" && linha[disableDelete]) || (action.icon === "fa-edit" && linha[disableEdit])}
                                                            />
                                                        }
                                                        {action.icon &&
                                                            <Button key={index}
                                                                circle
                                                                block
                                                                onClick={action.handleOnClick(linha[idData])}
                                                                icon={"fas " + action.icon}
                                                                disabled={(action.icon === "fa-trash" && linha[disableDelete]) || (action.icon === "fa-edit" && linha[disableEdit])}
                                                            />
                                                        }
                                                    </>
                                                ))}
                                            </td>
                                        }
                                    </tr>
                                    {subHeaders &&
                                        <CollapseTable ref={(e) => { openCollapsedRef(e, index) }}
                                            indexRow={index} isOpen={collapsed === index} >
                                            <td colSpan={(actionsTable && checked ? headers.length + 3 : (actionsTable || checked) ? headers.length + 2 : headers.length + 1)} scope="colgroup" >
                                                {(subHeaders as ISubHeader[]).map((subHeaders: ISubHeader, index: number) => (
                                                    <p key={index}>
                                                        <span className={'text-up-01 text-weight-medium mr-3 text-interactive'}>{(subHeaders as ISubHeader).label}:</span>
                                                        {linha[subHeaders.field]}
                                                    </p>
                                                ))}
                                            </td>
                                        </CollapseTable>
                                    }
                                </>
                            ))}
                        </tbody>
                    }

                    {children}
                </table>
                {showPagination && <div className="table-footer">
                    <Pagination
                        type="contextual"
                        itemCount={recordCount}
                        onChange={(value) => setPageNumber(value)}
                        onChangePageSize={(value) => setPageSize(value)}
                        showPageCombo={showPageSelector}
                    />

                </div>}

                {footerButtons &&
                    <Row className="flex-row-reverse">
                        {(footerButtons as IFooterButton[]).map((footerButton: IFooterButton, index: number) => (
                            footerButton.hideIfNotChecked ? (
                                isCheck.length > 0 && (
                                    <Col sm={12} md={3} lg={3} mt="3" key={index}>
                                        <Button
                                            type="submit"
                                            primary={footerButton.type === 'primary'}
                                            secondary={footerButton.type !== 'primary'}
                                            className="mt-4"
                                            block={true}
                                            onClick={footerButton.handleOnClick}
                                        >
                                            {footerButton.value}
                                        </Button>
                                    </Col>
                                )
                            ) : (
                                <Col sm={12} md={3} lg={3} mt="3" key={index}>
                                    <Button
                                        type="submit"
                                        primary={footerButton.type === 'primary'}
                                        secondary={footerButton.type !== 'primary'}
                                        className="mt-4"
                                        block={true}
                                        onClick={footerButton.handleOnClick}
                                    >
                                        {footerButton.value}
                                    </Button>
                                </Col>
                            )
                        ))}
                    </Row>
                }
            </div>
        );
    }
);

Table.displayName = 'Table';

export default Object.assign(Table, {
    Content: CollapseTable
});