import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Links.style.scss'
import {Card, Col, Collapse, Item, List, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
export default function Links() {

    const { getLinks } = useRequisicaoSite();


    const [links, setDownloads] = useState<any>([])

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getLinks().then(r => {
                setDownloads(r.data)
            });

        };
        handleDataFiltro()
    }, [])

    return (
        <div className={'links'}>
            <h1 className={'titulo'}>Links Úteis</h1>
            <List>
            {Array.isArray(links) && links?.map((item) => {
                return (
                    <Item link={item.link} key={'download-collapse-' + item.id} showDividerAfter  className={'py-3'}>
                        <Row>
                            <Col auto>
                                <i className="fas fa-external-link-alt"></i>
                            </Col>
                            <Col>
                                <span dangerouslySetInnerHTML={{__html: item.titulo}} />
                            </Col>
                        </Row>
                    </Item>
                )
            })}
                {!Array.isArray(links) && <p>No data available.</p>}
        </List>

        </div>

    )
}