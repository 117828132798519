import api from "../api";
export const useRequisicaoSite = () => {

    const getSecretarias = async (filtros) => {
        return api.get('/api/web/secretaria', { params: filtros })
    }
    const getNoticias = async (filtros) => {
        return api.get('/api/web/noticias', { params: filtros })
    }
    const getSlids = async (filtros) => {
        return api.get('/api/web/slides', { params: filtros })
    }
    const getPopup = async (filtros) => {
        return api.get('/api/web/popups', { params: filtros })
    }
    const getPrefeitos = async () => {
        return api.get('/api/web/prefeitos')
    }
    const getNossaCidade = async () => {
        return api.get('/api/web/nossa-cidade')
    }
    const getPerguntasFrequentes = async (filtros) => {
        return api.get('/api/web/perguntas-frequentes', { params: filtros })
    }
    const getDownloads = async (filtros) => {
        return api.get('/api/web/downloads', { params: filtros })
    }
    const getLinks = async (filtros) => {
        return api.get('/api/web/links', { params: filtros })
    }
    const getConcursos = async (filtros) => {
        return api.get('/api/web/concursos', { params: filtros })
    }
    const getConcursosAnos = async () => {
        return api.get('/api/web/concursos-anos')
    }
    const criarOuvidoria = (dadosFormulario) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return api.post('/api/web/ouvidoria', dadosFormulario, config);
    }
    const criarContato = (dadosFormulario) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return api.post('/api/web/contato', dadosFormulario, config);
    }
    return {
        getSecretarias, getNoticias, getPrefeitos,getNossaCidade, getPerguntasFrequentes,getDownloads,getLinks,
        getConcursos, getConcursosAnos, criarOuvidoria, criarContato, getSlids, getPopup
    }
}
