import classNames from 'classnames';
import React, {useState} from 'react';
import IMtProps from '../IMtProps';
import { useSpreadProps } from '../Util/useSpreadProps';
import { useMtProps } from '../Util/useMtProps';
import "./TopHeader.style.scss"
import {Col, Container, Header, List, Row} from "../index";
import CustomTag from "../CustomTag/CustomTag";
import {useNavigate} from "react-router-dom";
import mainLogo from './../../images/vilhena.png';

export interface TopHeaderProps  extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
      auto?: number |boolean
}

const TopHeader = React.forwardRef<HTMLDivElement, TopHeaderProps>(
    ({className, children,
         auto, ...props}, ref) => {
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);
        const [features, setFeatures] = useState([
            {icon: 'fab fa-facebook', href: 'https://www.facebook.com/PrefeituraVilhena'},
            {icon: 'fab fa-instagram', href: 'https://www.instagram.com/prefeituravilhena?igsh=MWkyb3NibHVmMWoxcQ=='},
            // {icon: 'fab fa-facebook', href: 'https://pt-br.facebook.com/municipiodevilhena/'},
            // {icon: 'fab fa-instagram', href: 'https://www.instagram.com/municipiodevilhena/'},
            {icon: 'fab fa-twitter', href: 'https://twitter.com/Pref_Vilhena'},
            {icon: 'fab fa-youtube', href: 'https://www.youtube.com/prefeituradevilhena'},
            {icon: 'fab fa-whatsapp', href: 'https://wa.me/556939197081/'},
        ]);

        const navigate = useNavigate();
        const handleClick = (value) => {
            console.log(value)
            navigate(value !== '' ? '/busca/'+ value : '/');
        };

        return (
            <>
                <div
                    ref={ref}
                    className={classNames(
                        'top-header',
                        auto && (typeof auto === 'boolean' ? 'col-auto' : `col-auto-${auto}`),
                        ...mtProps,
                        className
                    )}
                    {...spreadProps}

                >
                    <Container>
                        <Row className={'flex-fill align-items-center'}>
                            <Col className={'d-flex align-items-center'}>
                                Acesso à informação <span className="br-divider vertical mx-half mx-2 my-2"></span>
                                <a href="http://transparencia.vilhena.ro.gov.br/"> Transparência</a><span
                                className="br-divider vertical mx-half mx-2 my-2"></span>
                                <a href="/acesso-a-informacao/ouvidoria"> Ouvidoria</a>
                            </Col>
                            <Col className={'col-2'}>
                                <List horizontal>
                                    {Array.isArray(features) && features?.map((feature, index) =>

                                        <div key={index} className="align-items-center br-item">
                                            <CustomTag tagName={feature.href && 'a'} href={feature.href}
                                                       className="br-button circle small">
                                                {feature.label &&
                                                    <button onClick={feature.onClick} className="br-button circle small"
                                                            type="button" aria-label={feature.label}><i
                                                        className={feature.icon} aria-hidden="true"></i><span
                                                        className="text">{feature.label}</span>
                                                    </button>
                                                }
                                                {!feature.label &&
                                                    <i className={feature.icon} aria-hidden="true"></i>
                                                }

                                            </CustomTag>
                                        </div>
                                    )}
                                </List>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Header
                    fixed
                    urlLogo={mainLogo}
                    systemName={"Prefeitura municipal \n Vilhena"}
                    loggedIn={true}
                    density={'small'}
                    quickAccessLinks={[
                        {label: 'O Município', href: '/o-municipio'},
                        {label: 'Secretarias', href: '/secretaria'},
                        {label: 'Serviços', href: '/servicos'},
                        {label: 'Acesso à Informação', href: '/acesso-a-informacao'},
                        {label: 'Contato', href: '/contato'}
                    ]}
                    showSearchBar={true}
                    onSearch={handleClick}
                    showMenuButton={false}
                    loggedIn={true}
                />
            </>

        );
    }
);

TopHeader.displayName = 'TopHeader';

export default TopHeader;