import React, {useEffect, useState} from "react";
import { Col, Container, Item, List, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import {useNavigate} from "react-router-dom";
import Galeria from "../components/galeria/Galeria";

export default function Municipio() {

    const [nossaCidade, setNossaCidade] = useState<any>()
    const { getNossaCidade } = useRequisicaoSite();

    const navigate =  useNavigate();

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getNossaCidade().then(r => {
                setNossaCidade(r.data)
            });

        };
        handleDataFiltro()
    }, [])
    return (
        <div className={'secretaria'}>
            {nossaCidade &&
            <Container>
                <Row>
                    <Col className="col-3">
                        <List title="O Município" mt={'5'}>
                            <a href='#' onClick={() => navigate('/o-municipio')}>
                                <Item py={2} showDividerAfter>
                                    <Row>
                                        <Col auto>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Col>
                                        <Col>
                                            História de Vilhena
                                        </Col>
                                    </Row>
                                </Item>
                            </a>
                            <a href='#' onClick={() => navigate('/prefeitos')}>
                                <Item py={2} showDividerAfter>
                                    <Row>
                                        <Col auto>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Col>
                                        <Col>
                                            Galeria de Prefeitos
                                        </Col>
                                    </Row>
                                </Item>
                            </a>
                        </List>
                    </Col>
                    <Col>
                        <div className="mt-5">
                            <h1 className={'titulo'} dangerouslySetInnerHTML={{__html: nossaCidade.titulo}}/>
                            <div className="card-text"
                                 dangerouslySetInnerHTML={{__html: nossaCidade.artigo}}/>
                        </div>
                        <div className="mt-5">
                            <Galeria galeriaList={nossaCidade.fotos}></Galeria>
                        </div>
                    </Col>
                </Row>
            </Container>
            }
        </div>
    )
}