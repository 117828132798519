import * as Yup from 'yup';

export const useContatoFormSchema = () => {
    const contatoFormInicial = {
        nome: '',
        email: '',
        cpf: '',
        rg: '',
        telefone: '',
        cep: '',
        cidade: '',
        bairro: '',
        endereco: '',
        solicitacao: '',
    }

    const contatoFormSchema = Yup.object().shape({
        nome: Yup.string().required("Campo Obrigatório"),
        email: Yup.string().required("Campo Obrigatório"),
        cpf: Yup.string().required("Campo Obrigatório"),
        telefone: Yup.string().required("Campo Obrigatório"),
        solicitacao: Yup.string().required("Campo Obrigatório"),
    })

    return {
        contatoFormInicial,
        contatoFormSchema,
    }
}