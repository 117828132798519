import React, { useCallback, useEffect, useState } from "react";
import {Col, Row} from "../../../components";

const Galeria = (props) => {
    const { galeriaList } = props;
    return (
        <>
            <div className="main-galeria">
                <h1 className="fw-light titulo">Galeria de Fotos</h1>
                <Row className={'d-flex align-content-start flex-wrap'}>
                    {Array.isArray(galeriaList) && galeriaList?.map((linha) => (
                        <Col lg={3} md={3} sm={6} p={2}>
                            <a href="#" className="d-block mb-4 h-50 overflow-hidden">
                                <img className="img-fluid img-thumbnail rounder-md "
                                     src={linha.foto} alt=""/>
                            </a>
                        </Col>
                    ))}
                    {!Array.isArray(galeriaList) && <p>No data available.</p>}
                </Row>

            </div>
        </>
    )
}

export default Galeria;
