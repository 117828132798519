import '@govbr-ds/core/dist/core-base.min.css';

import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import IMtProps from '../../IMtProps';
import { useSpreadProps } from '../../Util/useSpreadProps';
import { useMtProps } from '../../Util/useMtProps';
import './style.scss';
import useUniqueId from "../../Util/useUniqueId";
import useCommonProperties from "../../Util/useCommonProperties";

export interface CollapseTableProps  extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
    /** referencia do collapsed. */
    isOpen: number,
    /** referencia index do map ou foreach*/
    indexRow: number
}

export interface CollapseTableRef extends HTMLDivElement {
    element: HTMLDivElement
}

const CollapseTable = React.forwardRef<CollapseTableRef, CollapseTableProps>(
    ({className, children,isOpen ,
         indexRow,id,...props}, ref) => {
        const fid = useUniqueId(id, 'collapsedTable_____');
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);
        const [collapsedUp, setCollapsed] = useState<number>(null);
        const refTr = useRef<HTMLTableRowElement>(null);

        useCommonProperties<CollapseTableRef>(ref, refTr, {
            get element() {
                return refTr.current;
            }
        });
        useEffect( () => {

            setCollapsed(isOpen);
        }, [isOpen])

        return (
            <tr
                ref={refTr}
                id={fid}
                className={classNames(
                    'collapse-' + indexRow,
                    isOpen ? 'collapse' :'collapse collapsed',
                    className,
                    ...mtProps
                )}
                {...spreadProps}

            >
                {children}
            </tr>
        );
    }
);

CollapseTable.displayName = 'CollapseTable';

export default CollapseTable;