import React from 'react';
import {useLocation} from "react-router-dom";
import mainLogo from '../../../images/vilhena.png';
import "./style.scss";
import Spinner from '../../../utils/spinner';

export default function BrFooter() {
    const location = useLocation();


        return (
            <>
                <footer className="br-footer">
                    <div className="container-fluid p-0 ">
                        <div className="logo ml-10x">
                            <img src={mainLogo}
                                 alt="Imagem"/>
                        </div>
                        <span className="br-divider my-3"></span>
                        <div className="info d-flex align-items-center justify-content-center">
                            <div className="text-down-01 text-medium pb-3">&copy; {new Date().getFullYear() } | Prefeitura Municipal Vilhena </div>
                        </div>
                    </div>
                </footer>
                <Spinner></Spinner>
            </>

    );
}