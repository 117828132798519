import "./spinner.scss";
export default function Spinner() {
    return (<>
        <div id="spinner" >
            <div className="content d-flex flex-column align-items-center">
                <div className="loading medium"></div>
                <span className="rotulo mt-2">Carregando...</span>
            </div>
        </div>
    </>)
}
