import React, { useEffect, useState} from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import "./Imprensa.style.scss";
import {Button, Card, Col, Container, Item, List, Row} from "../../components";
import ApexCharts from 'apexcharts'
import Noticia from "./Noticia";
import NoticiaLista from "./NoticiaLista";
import Ouvidoria from "../Ouvidoria/Ouvidoria";
import * as Entities from "html-entities";
import PerguntasFrequentes from "../PerguntasFrequentes/PerguntasFrequentes";
import Downloads from "../Downloads/Downloads";
import Links from "../Links/Links";
import Concurso from "../Concurso/Concurso";

export default function Imprensa() {

    const navigate = useNavigate();
    const [noticia, setNoticia] = useState<any>([])

    const handleVerNoticias =  () => {
        navigate('/acesso-a-informacao');
    };
    const handleVerConcursos =  () => {
        navigate('/acesso-a-informacao/concursos');
    };
    const handleVerDownloads =  () => {
        navigate('/acesso-a-informacao/downloads');
    };
    const handleLinksUteis =  () => {
        navigate('/acesso-a-informacao/links');
    };
    const handleVerOuvidoria =  () => {
        navigate('/acesso-a-informacao/ouvidoria');
    };
    const handleVerPerguntasFrequentes =  () => {
        navigate('/acesso-a-informacao/perguntas-frequentes');
    };
    useEffect(() => {

    }, []);
    return (
        <div className={'imprensa'}>
            <Container>
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        {noticia &&
                        <>
                            <List title="Acesso à Informação" mt={'5'}>

                                <a href='#' onClick={() => handleVerConcursos()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Concursos
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                                <a href='#' onClick={() => handleVerDownloads()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Downloads
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                                <a href='#' onClick={() => handleLinksUteis()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Links Úteis
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                                <a href='#' onClick={() => handleVerNoticias()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Notícias
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                                <a href='#' onClick={() => handleVerOuvidoria()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Ouvidoria
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                                <a href='#' onClick={() => handleVerPerguntasFrequentes()}>
                                    <Item showDividerAfter>
                                        <Row>
                                            <Col auto>
                                                <i className="far fa-arrow-alt-circle-right"></i>
                                            </Col>
                                            <Col>
                                                Perguntas Frequentes
                                            </Col>
                                        </Row>
                                    </Item>
                                </a>
                            </List>
                        </>}
                    </Col>
                    <Col lg={9} md={9} sm={12} py={5}>
                        <Routes>
                            <Route path="/" element={<NoticiaLista noticia={noticia} setNoticia={setNoticia}/>}/>
                            <Route path="/noticia/:slug"
                                   element={<Noticia noticia={noticia} setNoticia={setNoticia}/>}/>
                            <Route path="/ouvidoria"
                                   element={<Ouvidoria ouvidoria={noticia} setOuvidoria={setNoticia}/>}/>
                            <Route path="/perguntas-frequentes" element={<PerguntasFrequentes/>}/>
                            <Route path="/downloads" element={<Downloads/>}/>
                            <Route path="/links" element={<Links/>}/>
                            <Route path="/concursos" element={<Concurso/>}/>
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}