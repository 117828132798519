import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Downloads.style.scss'
import {Card, Col, Collapse, Item, List, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
export default function Downloads() {

    const { getDownloads } = useRequisicaoSite();


    const [downloads, setDownloads] = useState<any>([])

    useEffect(() => {
        const handleDataFiltro = async () => {
            await getDownloads().then(r => {
                setDownloads(r.data)
            });

        };
        handleDataFiltro()
    }, [])

    return (
        <div className={'downloads'}>
            <h1 className={'titulo'}>Downloads </h1>
            {Array.isArray(downloads) && downloads?.map((linha) => (
                <Collapse title={linha.categoria} key={'download-collapse-' + linha.id}>
                    <List>
                        {Array.isArray(linha.downloads) && linha.downloads?.map((item) => {
                            return (
                                <Item link={item.arquivo} key={'download-collapse-' + item.id} showDividerAfter >
                                    <Row>
                                        <Col auto>
                                            <i className="fas fa-cloud-download-alt"></i>
                                        </Col>
                                        <Col>
                                            <span dangerouslySetInnerHTML={{__html: item.descricao}} />
                                        </Col>
                                    </Row>
                                </Item>
                            )
                        })}
                    </List>
                </Collapse>
            ))}
            {!Array.isArray(downloads) && <p>No data available.</p>}

        </div>

    )
}