import axios from 'axios';
import { getCookie } from '../utils';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://dev-vm-clah8-02.fnde.gov.br/fabrica/herisson/siscacs-php/public/api", //process.env.API_URL
    // timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    },
    withCredentials: false
});

api.interceptors.request.use(async (config) => {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_API_URL;

    try {
        const element = document.querySelector("#spinner");
        if (element)
            element.classList.add("show");
    }
    catch (e) {
        const element = document.querySelector("#spinner");
        if (element)
            element.classList.remove("show");
    }

    return config;
}, function (error) {
    const element = document.querySelector("#spinner");
    if (element)
        element.classList.remove("show");
    return Promise.reject(error);
});

api.interceptors.response.use(async (config) => {
    try {
        const element = document.querySelector("#spinner");
        if (element)
            element.classList.remove("show");
    }
    catch (e) {
        const element = document.querySelector("#spinner");
        if (element)
            element.classList.remove("show");
    }

    return config;
}, function (error) {
    const element = document.querySelector("#spinner");
    if (element)
        element.classList.remove("show");
    return Promise.reject(error);
});

export default api;
