import {format, addDays, subDays, parse, getYear, startOfYear, endOfMonth, isWithinInterval, getMonth} from 'date-fns';


export const listMtProps = ['mt', 'mt-sm', 'mx', 'my', 'py', 'mb', 'align-items-center', 'justify-content', 'p', 'p-sm', 'm', 'mr', 'px', 'py'];

export const mapaIcones = new Map<string, string>([
    ['danger', 'fas fa-times-circle'],
    ['success', 'fas fa-check-circle'],
    ['warning', 'fas fa-exclamation-triangle'],
    ['info', 'fas fa-check-circle']
]);

/** Função que encontrei no stackoverflow em https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter */
export function updateQueryStringParameter(uri : string, key : string, value : string) {
    const re = new RegExp('([?&])' + key + '=.*?(&|#|$)', 'i');
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        let hash =  '';
        if( uri.indexOf('#') !== -1 ){
            hash = uri.replace(/.*#/, '#');
            uri = uri.replace(/#.*/, '');
        }
        const separator = uri.indexOf('?') !== -1 ? '&' : '?';
        return uri + separator + key + '=' + value + hash;
    }
}

export function removeValoreNull(object : any) {
    const params = {};
    for (const [key, value] of object) {
        let label = key;
        if (value && value !== 'undefined' && value !== null){
            params[label] = value;
        }
    }
    return params
}

export function isString(value: any): value is string {
    return typeof value === 'string';
}

export function objetoParaGetParams(objeto) {
    var parametros = [];
    for (var chave in objeto) {
        if (objeto.hasOwnProperty(chave) && encodeURIComponent(objeto[chave]) !== 'undefined'  && encodeURIComponent(objeto[chave]) !== '') {
            parametros.push(encodeURIComponent(chave) + "=" + encodeURIComponent(objeto[chave]));
        }
    }
    return parametros.join("&");
}

//RETORNA PRIMEIRO DIA DO ANO
export function minDateByAno(ano) {
    const primeiroDiaAno = new Date(ano, 0, 1);

    return primeiroDiaAno;
}

//RETORNA ULTIMO DIA DO ANO
export function maxDateByAno(ano) {
    let ultimoDiaAno: Date | null = null;
    if(typeof ano !== 'number'){
        const anoNumber = parseInt(ano);
        ultimoDiaAno = subDays(new Date(anoNumber + 1, 0, 1), 1);
    } else {
        ultimoDiaAno = subDays(new Date(ano + 1, 0, 1), 1);
    }

    return ultimoDiaAno;
}

// RETORNA INICIO E FIM DO PERIODO DE VINCULACAO EM UM ARRAY
export function dateByPeriodoVinculacao(periodo, ano){
    const anoNumber = parseInt(ano);

    let inicioPeriodo: string;
    let fimPeriodo: Date;

    switch ( periodo ) {
        case '1':
                inicioPeriodo = new Date(ano , 0, 1);
                fimPeriodo = subDays(new Date(ano, 2, 1), 1);

                break;
        case '2':
                inicioPeriodo = new Date(ano , 2, 1);
                fimPeriodo = subDays(new Date(ano, 4, 1), 1);

                break;
        case '3':
                inicioPeriodo = new Date(ano , 4, 1);
                fimPeriodo = subDays(new Date(ano, 6, 1), 1);

                break;
        case '4':
                inicioPeriodo = new Date(ano , 6, 1);
                fimPeriodo = subDays(new Date(ano, 8, 1), 1);

                break;
        case '5':
                inicioPeriodo = new Date(ano , 8, 1);
                fimPeriodo = subDays(new Date(ano, 10, 1), 1);

                break;
        case '6':
                inicioPeriodo = new Date(ano , 10, 1);
                fimPeriodo = subDays(new Date(ano, 12, 1), 1);

                break;
    }

    inicioPeriodo = format(inicioPeriodo, 'dd/MM/yyyy');
    fimPeriodo = format(fimPeriodo, 'dd/MM/yyyy');

    return {
        inicioPeriodo: inicioPeriodo,
        fimPeriodo: fimPeriodo
    };
}

export function periodoVinculacaoByDate(date){
    let retorno = '';
    //DATE exemplo = '01/01/2023'
    // Use a função parse para converter a string em um objeto de data
    const data = parse(date, 'dd/MM/yyyy', new Date());
    // Use a função getMonth para obter o número do mês (0-11)
    //0 = janeiro
    //11 = dezembro
    const numeroMes = getMonth(data);

    switch (numeroMes){
        case 0:
        case 1:
            retorno = '1';
            break;

        case 2:
        case 3:
            retorno = '2'
            break;

        case 4:
        case 5:
            retorno = '3'
            break;

        case 6:
        case 7:
            retorno = '4'
            break;

        case 8:
        case 9:
            retorno = '5'
            break;

        case 10:
        case 11:
            retorno = '6'
            break;
    }

    return retorno;
}

export function checkFieldErrors(values, validacao){
    const errosPorCampo = {};

    try {
        validacao.validateSync(values, { abortEarly: false });
    } catch (error) {
        if (error.inner) {
            error.inner.forEach((erro) => {
                const nomeDoCampo = erro.path;
                const mensagemDeErro = erro.message;
                errosPorCampo[nomeDoCampo] = mensagemDeErro;
            });
        } else {
            console.error('Erro geral:', error.message);
        }
    }

    return errosPorCampo;
}

//FUNCOES:
// - Limita a data minima de termino a 30 dias após o inicio
// - Limita a data maxima de termino a 45 dias após o inicio
// - Limita para ANO ATUAL
// - Caso mude a data inicio, muda automaticamente a data fim para +45 dias
// - A data termino NUNCA será em periodo vinculativo diferente da data inicio
// async function handleChangeDtInicio(dt_inicio) {
//     const dtIni = parse(dt_inicio.target.value, 'dd/MM/yyyy', new Date());
//     const dtMinTer = addDays(dtIni, 30);
//     const dtMaxTer = addDays(dtIni, 45);
//     const dtMaxTerFormatada = format(addDays(dtIni, 45),'dd/MM/yyyy');
//
//     //TRABALHANDO DATAS PARA CONDIÇÃO
//     // Mude a estrutura da string da data
//     const dtmaxtermino = parse(dtMaxTerFormatada, 'dd/MM/yyyy', new Date());
//     // Obtenha o ano das datas
//     const anodtmaxtermino = getYear(dtmaxtermino);
//     const anoAtual = getYear(new Date());
//
//     //PEGAR PERIODO VINCULACAO QUE ESTÁ ESSA DATA
//     let periodoVinculacaoDtIni = periodoVinculacaoByDate(dt_inicio.target.value);
//     let periodoVinculacaoDtTer = periodoVinculacaoByDate(dtMaxTerFormatada);
//
//     if(periodoVinculacaoDtIni != periodoVinculacaoDtTer){
//         //DATA MAXIMA TEM QUE SER NO FIM DO PERIODO VINCULATIVO DA DTINI
//         let ultimoDiaPeriodoDtIni = format(dateByPeriodoVinculacao(periodoVinculacaoDtIni, anoAtual).fimPeriodo,'dd/MM/yyyy');
//         setMaxDateTermino(ultimoDiaPeriodoDtIni);
//         formikTurmaNovo.values.dt_termino = ultimoDiaPeriodoDtIni;
//     } else {
//         if(anodtmaxtermino != anoAtual){
//             setMaxDateTermino(maxDateByAno(anoAtual))
//             formikTurmaNovo.values.dt_termino = format(maxDateByAno(anoAtual),'dd/MM/yyyy');
//         } else {
//             setMaxDateTermino(dtMaxTer);
//             formikTurmaNovo.values.dt_termino = dtMaxTerFormatada;
//         }
//     }
//     setMinDateTermino(dtMinTer);
//     formikTurmaNovo.values.dt_inicio = dt_inicio.target.value;
// }

export function maxDateByPeriodoVinculacao(periodo){

}
