import classNames from 'classnames';
import React, { memo } from 'react';
import CardContent from './CardContent';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';
import IMtProps from '../IMtProps';
import { useSpreadProps } from '../Util/useSpreadProps';
import { useMtProps } from '../Util/useMtProps';
import useUniqueId from '../Util/useUniqueId';
import  './Card.style.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
    /** Se o card mostra muda de comportamento ao passar o mouse em cima. */
    hover?: boolean,

    /** Se ele é fixo horizontalmente. */
    hFixed?: boolean,

    /** Se tiver ativa o link para todo o card*/
    href? : string | React.MouseEventHandler<HTMLAnchorElement>,
    /** Se ele está desabilitado. */
    disabled?: boolean

    /** Densidade do card */
    density?: 'small' | 'medium' | 'large'
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
    ({className, id, children,href,
         density, hover, hFixed
         , disabled, ...props}, ref) => {
        const fid = useUniqueId(id, 'card_____');
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);

        return (
            <>
                {href && typeof href === 'string' &&
                    <a href={href} target={'_blank'}>
                        <div
                            ref={ref}
                            id={fid}
                            className={classNames(
                                'br-card',
                                density === 'small' && 'br-card-small',
                                density === 'large' && 'br-card-large',
                                hover && 'hover',
                                hFixed && 'h-fixed',
                                disabled && 'disabled',
                                ...mtProps,
                                className
                            )}
                            {...spreadProps}
                        >
                            {href && typeof href != 'string' &&
                                <a className="br-item" onClick={href} href="#">{children}</a>
                            }
                            {children}
                        </div>
                    </a>
                }

                {href && typeof href != 'string' &&
                    <a className="br-item" onClick={href} href="#">
                        <div
                            ref={ref}
                            id={fid}
                            className={classNames(
                                'br-card',
                                density === 'small' && 'br-card-small',
                                density === 'large' && 'br-card-large',
                                hover && 'hover',
                                hFixed && 'h-fixed',
                                disabled && 'disabled',
                                ...mtProps,
                                className
                            )}
                            {...spreadProps}
                        >
                            {children}
                        </div>
                    </a>
                }

                {!href&&
                    <div
                        ref={ref}
                        id={fid}
                        className={classNames(
                            'br-card',
                            density === 'small' && 'br-card-small',
                            density === 'large' && 'br-card-large',
                            hover && 'hover',
                            hFixed && 'h-fixed',
                            disabled && 'disabled',
                            ...mtProps,
                            className
                        )}
                        {...spreadProps}
                    >
                        {children}
                    </div>
                }
            </>

    )
        ;
    }
);

Card.displayName = 'Card';

export default Object.assign(Card, {
    Header: CardHeader,
    Content: CardContent,
    Footer: CardFooter
});