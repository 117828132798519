import classNames from 'classnames';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import IMtProps from '../IMtProps';
import { useSpreadProps } from '../Util/useSpreadProps';
import { useMtProps } from '../Util/useMtProps';
import Message from '../Message/Message';
import { mapaIcones } from '../Util/Util';
import useCommonProperties from '../Util/useCommonProperties';
import useUniqueId from '../Util/useUniqueId';
import InputMaskL from "react-input-mask";

export interface InputProps extends React.HTMLAttributes<HTMLInputElement>, IMtProps {
    /** Label do input. */
    label?: string | React.ReactElement;
    /** Label em baixo do Input. */
    bottomLabel?: string;
    /** Placeholder do input. */
    placeholder?: string,
    /** Tipo do input. Por padrão, é type="text". */
    type?: string,
    /** Tamanho do input.
     *
     * - small: pequeno
     * - normal: normal
     * - large: largo.
     */
    density?: 'small' | 'normal' | 'large',
    /** Classe font awesome do ícone do input. */
    icon?: string,
    /** Botão no canto direito do input. */
    button?: React.ReactElement,
    /** Se é do tipo "highlight" */
    highlight?: boolean,
    /** Se a label fica na lateral. */
    inline?: boolean,
    /** Valor do input. */
    defaultValue?: string,
    /** Status do input muda a sua cor.
     *
     * - success: verde
     * - danger: vermelho
     * - info: azul
     * - warning: amarelo
     */
    status?: 'success' | 'danger' | 'info' | 'warning',
    /** Texto de feedback que aparece embaixo do item, com a cor de fundo de acordo com o status escolhido. */
    feedbackText?: string,
    /** */
    mask?: string,
    autoFocus?: boolean
    value?: string
}

export interface InputRef extends HTMLInputElement {
    element: HTMLInputElement | null,
    inputWrapper?: HTMLDivElement | null,
    label: HTMLLabelElement | null,
    bottomLabel: HTMLLabelElement | null,
    labelGroup: HTMLDivElement | null,
    bottomLabelGroup: HTMLDivElement | null,
    icon: HTMLElement | null,
    iconGroup: HTMLDivElement | null
}

const Input = React.forwardRef<InputRef, InputProps>(
    ({ className, id, children, label , bottomLabel,
         placeholder, type = 'text', density = 'normal', icon,
         button, highlight, inline, defaultValue,
         status, feedbackText,
         autoFocus = false, ...props }, ref) => {
        const fid = useUniqueId(id, 'input_____');
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);

        // Implementando os refs de cada um dos elementos
        const refInput = useRef<HTMLInputElement>(null);
        const refInputWrapper = useRef<HTMLDivElement>(null);
        const refInputContent = useRef(null);
        const refLabel = useRef<HTMLLabelElement>(null);
        const refBottomLabel = useRef<HTMLLabelElement>(null);
        const refLabelGroup = useRef<HTMLDivElement>(null);
        const refBottomLabelGroup = useRef<HTMLDivElement>(null);
        const refIcon = useRef<HTMLElement>(null);
        const refIconGroup = useRef<HTMLDivElement>(null);

        const [valueInput, setValueInput] = useState(defaultValue);



        useCommonProperties<InputRef>(ref, refInput, {
            get element() {
                return refInput.current;
            },
            get inputWrapper() {
                return refInputWrapper.current;
            },
            get label() {
                return refLabel.current;
            },
            get bottomLabel() {
                return refBottomLabel.current;
            },
            get labelGroup() {
                return refLabelGroup.current;
            },
            get bottomLabelGroup() {
                return refBottomLabelGroup.current;
            },
            get icon() {
                return refIcon.current;
            },
            get iconGroup() {
                return refIconGroup.current;
            }
        });

        const noOnChange  = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValueInput(event.target.value)
        }
        const handleOnChange = props.onChange ? props.onChange : noOnChange;

        return (
            <div

                ref={refInputWrapper}
                className={classNames(
                    'br-input',
                    ((density === 'small') && 'small'),
                    ((density === 'large') && 'large'),
                    (highlight && 'input-highlight'),
                    (inline && 'input-inline'),
                    status,
                    className,
                    ...mtProps
                )}
            >
                {label && <div ref={refLabelGroup} className="ml-auto input-label"><label ref={refLabel} htmlFor={fid}>{label}</label></div>}
                <div ref={refInputContent} className="input-content">
                    <div className="input-group">
                        {icon && <div ref={refIconGroup} className="input-icon"><i ref={refIcon} className={icon} aria-hidden="true"></i></div>}
                        {!props.mask &&
                            <input id={fid} ref={refInput} type={type} placeholder={placeholder} value={valueInput}
                                   onChange={handleOnChange} autoFocus  {...spreadProps} />
                        }
                        {props.mask &&
                            <InputMaskL
                                id={fid} ref={refInput} type={type} placeholder={placeholder} value={valueInput}
                                maskChar={null} autoFocus onChange={handleOnChange}  {...spreadProps}
                            />
                        }
                        {button}
                        {feedbackText && <Message category="feedback" type={status ? status : 'success'} icon={status && mapaIcones.get(status)}>{feedbackText}</Message>}
                        {children}
                    </div>
                </div>
                {bottomLabel && <div ref={refBottomLabelGroup} className="input-label"><label ref={refBottomLabel} htmlFor={fid}>{bottomLabel}</label></div>}
            </div>
        );
    }
);

Input.displayName = 'Input';

export default Input;