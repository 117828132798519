import * as React from 'react';
import  { useEffect, useContext } from "react";

import AppRoutes from "./routes";

import { AutorizacaoContext } from "./context/AutorizacaoContext";
import { getCookie } from "./utils";


//Template FNDE
import "./app.scss"


const App = () =>  {
    const { access_token, setAuthGovBr, authSisava, getAuthSisava, setAuthValidacoes, logout } =
        useContext(AutorizacaoContext);

    async function hasAccessToken() {
        if (
            typeof access_token === "string" &&
            access_token.length > 0 &&
            access_token !== "undefined"
        ) {
            await getAuthSisava(access_token);
        }
    }

    async function hasAuthGovBr() {
        let cookieGovBr = getCookie('authGovBr');
        if (typeof (cookieGovBr) === 'string' && cookieGovBr.length > 0 && cookieGovBr !== 'undefined') {
            setAuthGovBr(JSON.parse(cookieGovBr));
        }
    }

    async function hasValidacoes() {
        let js_validacoes = getCookie("validacoes");
        if (
            typeof js_validacoes === "string" &&
            js_validacoes.length > 0 &&
            js_validacoes !== "undefined"
        ) {
            await setAuthValidacoes(JSON.parse(js_validacoes));
        }
    }

    useEffect(() => {
        hasAccessToken();
    }, []);

    useEffect(() => {
        hasAuthGovBr();
    }, []);

    useEffect(() => {
        hasValidacoes();
    }, []);

    return (
        <AppRoutes />
    );
}

export default App