import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Ouvidoria.style.scss'
import {Card, Col, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import * as Entities from 'html-entities';
import Container from "../../components/Container";
import {Formik} from "formik";

import OuvidoriaForm from "./OuvidoriaForm";
import {useOuvidoriaFormSchema} from "./OuvidoriaFormSchema";

export default function Ouvidoria({ouvidoria, setOuvidoria}) {

    const navigate =  useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
        <div className={'ouvidoria'}>
            <h1 className={'titulo'}>Ouvidoria </h1>
            <p>Por meio da Ouvidoria do Município, é possível realizar solicitações para que a administração possa tomar as providências cabíveis, caso você tenha alguma sugestão ou reclamação, participe, faça com que nossa cidade cresça ainda mais.
                Ao realizar sua reclamação ou sugestão, você poderá fazer o acompanhamento da mesma através de nosso site. Para isso basta informar o número do protocolo no campo ao lado.</p>

                <OuvidoriaForm/>
        </div>

    )
}