import React from "react";
import { Col, Container, Item, List, Row} from "../../components";
import {Route, Routes, useNavigate} from "react-router-dom";
import ContatoForm from "./ContatoForm";


export default function Contato() {

    const navigate =  useNavigate();


    const handleVerContato =  () => {
        navigate('/contato');
    };

    return (
        <div className={'secretaria'}>
            <Container>
                <Row>
                    <Col className="col-3">
                        <List title="Secretarias">
                            <a href='#' onClick={() => handleVerContato()}>
                            <Item py={2} showDividerAfter>
                                <Row>
                                    <Col auto>
                                        <i className="far fa-arrow-alt-circle-right"></i>
                                    </Col>
                                    <Col>
                                        Fale Conosco
                                    </Col>
                                </Row>
                            </Item>
                            </a>
                        </List>
                    </Col>
                    <Col>
                        <Routes>
                            <Route path="/" element={<ContatoForm></ContatoForm>}/>
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}