import classNames from 'classnames';
import React, {useCallback, useState} from 'react';
import IMtProps from '../IMtProps';
import {useSpreadProps} from '../Util/useSpreadProps';
import {useMtProps} from '../Util/useMtProps';
import './style.scss';
import Row from "../Row";
import Col from "../Col";
import Button from "../Button";

export interface CollapseProps extends React.HTMLAttributes<HTMLDivElement>, IMtProps {
    /** referencia do collapsed. */
    collapsed?: number | boolean
    title: string
    valor?: number | string
}

const Collapse = React.forwardRef<HTMLDivElement, CollapseProps>(
    ({
         title,
         valor,
         collapsed,
         children,
         className,
         ...props
    }, ref) => {
        const mtProps = useMtProps(props);
        const spreadProps = useSpreadProps(props);
        const [open, setOpen] = useState<boolean>(false);

        const handleCollapse = useCallback(() => {
            setOpen(!open)
        }, [open]);

        return (
            <div
                ref={ref}
                className={classNames(
                    collapsed ? 'collapse collapsed' : 'collapse',
                    ...mtProps,
                    className
                )}
                {...spreadProps}

            >
                <Row className="collapseHeader my-3" onClick={handleCollapse}>
                    <Col className={"ml-2"} auto={true}>
                        {open &&
                            <Button small circle icon={'fas fa-chevron-down'} ></Button>
                        }
                        {!open &&
                            <Button small circle icon={'fas fa-chevron-up'} ></Button>
                        }
                    </Col>
                    <Col className={"mt-1"}>

                        <span className={"text-up-01 text-weight-bold"} dangerouslySetInnerHTML={{__html: title}}/>
                    </Col>
                    {valor &&
                        <Col className={"mt-1 text-right"}>
                            <span className={"text-up-01 text-weight-bold"} dangerouslySetInnerHTML={{__html: valor}}/>
                        </Col>
                    }
                </Row>
                <span className="br-divider mx-0"></span>
                <Row className={"collapseBody my-3 " + (open? 'd-block' : 'd-none')} >
                    <Col>
                        {children}
                    </Col>
                </Row>
            </div>
        );
    }
);

Collapse.displayName = 'Collapse';

export default Collapse;