import React, {useEffect, useState} from "react";
import { Col, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import NoticiaPainel from "../components/noticia-painel/NoticiaPainel";
import NoticiaCard from "../components/noticia-painel/NoticiaCard";

interface Mensagem {
    type: any;
    mensagem: string;
}

export default function SecretariaView(props) {

    const { id} = useParams();
    const { getNoticias,getSecretarias } = useRequisicaoSite();

    const [viewSecretaria, setViewSecretaria] = useState<any>( [])
    const [noticiaList, setNoticiaList] = useState<any>([])
    const handleDataNoticia = async () => {
        await getNoticias({limite: 4, page: 2, id_secretaria: id}).then(r => {
            const result = r.data.items;
            setNoticiaList(result)
        });
    };
    const handleDataSecretaria = async () => {
        await getSecretarias({id: id}).then(r => {
            const result = r.data[0];
            setViewSecretaria(result);
        });
    };
    useEffect(() => {
            handleDataSecretaria()
    }, [id]);

    useEffect(() => {
        handleDataNoticia()
    }, [])
    return (
        <div className={'secretaria-view'}>
            {viewSecretaria &&
                <>
                    <Row>
                        <Col sm={12} md={9} lg={9}>
                            <h3>SECRETARIA</h3>
                            <h1 dangerouslySetInnerHTML={{__html: viewSecretaria.secretaria }} className={'titulo'}/>
                            <div dangerouslySetInnerHTML={{__html: viewSecretaria.institucional}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: viewSecretaria.responsavel}}/>
                                <li>Telefone: {viewSecretaria.telefone}</li>
                                <li>Email: {viewSecretaria.email}</li>
                                <li dangerouslySetInnerHTML={{__html: 'Cargo: ' + viewSecretaria.cargo}}/>
                            </ul>

                        </Col>
                    </Row>
                </>
            }
            {noticiaList &&
                <>
                    <h6>SECRETARIA</h6>
                    <h2>Noticias</h2>
                    <Row>
                        <Col lg={1} md={1} className={'overflow-sm-hidden'}></Col>
                        <Col lg={10} md={10} sm={12}><NoticiaCard noticiaList={noticiaList} ></NoticiaCard></Col>
                        <Col lg={1} md={1} className={'overflow-sm-hidden'}></Col>
                    </Row>
                </>
            }
        </div>
    )
}